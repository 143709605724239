<template>
  <!--<PageHeader :title="title" :items="items" />-->
  <div class="row">
    <div class="col-12">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
        <div class="card">
          <div></div>
          <div class="col-12 mt-2">
            <div></div>
            <div class="card-header cardHeader-bg" style="padding: 14px;">
              <div
                v-if="alert"
                role="alert"
                aria-live="polite"
                aria-atomic="true"
                :class="
                  changeClasss ? 'alert alert-success' : 'alert alert-danger'
                "
              >
                {{ alertText }}
              </div>
              <h4 class="card-title mb-0">{{ headerTitle }}</h4>
            </div>
          </div>
          <div class="card-body">
            <div>
              <b-form-group
                id="input-group-2"
                label="Customer:"
                label-for="input-2"
              >
                <b-form-input
                  readonly
                  type="text"
                  v-model="customerId"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Restaurant Name:"
                label-for="input-2"
              >
                <b-form-input
                  readonly
                  type="text"
                  v-model="selected"
                ></b-form-input>
              </b-form-group>
              <br /><br />
            </div>
          </div>
        </div>
        <br />

        <div class="card">
          <template>
            <DetailMenuItems
              :meunItems="meunItems"
              @totalPriceIncrement="totalPriceIncrement"
              @deleteData="deleteData"
              :meunItemsTempArray="meunItemsTempArray"
              @showBulkPopupDeliveryAddress="showBulkPopupDeliveryAddress"
            />
          </template>

          <br />
        </div>

        <br />

        <div class="card">
          <template>
            <DiscountList
              @showDiscountedMenuItemModel="showDiscountedMenuItemModel"
              :discountList="discountList"
              :meunItemsTempArray="meunItemsTempArray"
            />
          </template>

          <br />
        </div>
        <br />

        <div class="card">
          <div></div>
          <div class="col-12 mt-2">
            <div></div>
          </div>
          <div class="card-body">
            <div>
              <div v-if="showInputForDelivery">
                <b-form-group
                  id="input-group-2"
                  label="Delivery Address:"
                  label-for="input-2"
                >
                  <b-form-input
                    v-model="deliveryAddress"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  label="Street Address:"
                  label-for="input-2"
                >
                  <b-form-input
                    v-model="street_address"
                    type="text"
                    placeholder="Street Address"
                  ></b-form-input>
                </b-form-group>
              </div>

              <b-form-group
                id="input-group-2"
                label="Total Price:"
                label-for="input-2"
              >
                <b-form-input
                  readonly
                  v-model="totalPrice"
                  type="number"
                  required
                  placeholder="Total Price"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                label="Order Status:"
                label-for="input-2"
              >
                <b-form-input
                  readonly
                  type="text"
                  v-model="orderStatus"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                v-if="showDeliveryTimeInput"
                id="input-group-3"
                label="Delivery Time:"
                label-for="input-3"
              >
                <b-form-input
                  id="input-3"
                  v-model="deliveryTime"
                  required
                ></b-form-input>
              </b-form-group>

              <b-form-group label="Type:">
                <b-form-radio-group id="radio-group-1" v-model="type">
                  <b-form-radio value="1">Delivery</b-form-radio>
                  <b-form-radio value="0">Pickup</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
              <b-form-group label="Payment Status:">
                <p>{{ paymentStatus }}</p>
              </b-form-group>

              <br /><br />
            </div>
          </div>
        </div>
        <br /><br />
        <template v-if="isShowDiscountMenuList">
          <DiscountMenuList
            :menuListArray="menuListArray"
            @closeModal="closeModal"
          />
        </template>

        <br /><br />
        <br />
      </b-form>
    </div>
  </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<script>
// import { appendRow, deleteRow } from "@/Helper/helpers.js";
import Vue from "vue";

import DiscountMenuList from "./DiscountMenuList";
import DetailMenuItems from "@/view/pages/Orders/DetailMenuItems";
import DiscountList from "@/view/pages/Orders/DiscountList";

// import VueGoogleAutocomplete from "vue-google-autocomplete";
import axios from "axios";
export default {
  components: {
    DiscountMenuList,
    DiscountList,
    // VueGoogleAutocomplete,
    DetailMenuItems,
  },
  data() {
    return {
      deliveryAddresses: [],
      meunItemsTempArray: [],
      menuListArray: [],
      paymentStatus: "",
      street_address: "",
      deliveryTime: null,
      deliveryAddress: null,
      minimunOrderAmout: null,
      showInputForDelivery: true,
      discountList: [],
      deliveryCost: null,
      customers: [],
      delivery_time: "",
      type: "1",
      customerId: null,
      headerTitle: "Order Detail",
      meunItems: [],
      meunItemsIds: [],
      totalPrice: 0.0,
      changeClasss: false,
      showDeliveryTimeInput: false,
      currentAddressname: "",
      email: "",
      name: "",
      size: null,
      id: "",
      selected: null,
      user: null,
      users: [],
      imagesShow: false,
      isShowDiscountMenuList: false,
      showDeleteModal: false,
      showModal: false,
      imagesArray: [],
      loans: [],
      isShowModel: false,
      alertText: "",
      alert: false,
      showImagesViewTable: false,
      deliveryAddressView: false,
      price: "",
      show: true,
      apiUrl: "/customer/orderPlace",
      orderStatus: null,
      orderStatuses: [
        { text: "Select Order Status", value: null },
        "accepted",
        "rejected",
        "dispatch",
        "pending",
      ],
    };
  },

  mounted() {
    this.getAllRestaurant();
    this.getAllCustomers();
    this.editDataApppend();
  },
  methods: {
    onSelect(option) {
      this.totalPrice = 0.0;
      this.meunItemsIds = [];
      this.meunItemsTempArray = [];
      this.deliveryAddress = null;
      this.meunItems = option.meunItems;
      this.deliveryAddresses = option.addresses;
    },

    showDiscountedMenuItemModel(disountedMenuItemArray) {
      this.menuListArray = disountedMenuItemArray;
      this.isShowDiscountMenuList = true;
    },

    onSelectDeliveryAddress(option) {
      this.minimunOrderAmout = option.minimum_order_amount;
      this.deliveryCost = option.delivery_cost;
      if (parseFloat(option.delivery_cost) > 0) {
        let grandTotal =
          parseFloat(this.totalPrice) + parseFloat(option.delivery_cost);
        this.totalPrice = parseFloat(grandTotal).toFixed(2);
      }
    },
    onDeliveryAddressRemove(option) {
      this.minimunOrderAmout = option.minimum_order_amount;
      if (parseFloat(option.delivery_cost) > 0) {
        let grandTotal =
          parseFloat(this.totalPrice) - parseFloat(option.delivery_cost);
        this.totalPrice = parseFloat(grandTotal).toFixed(2);
      }
    },

    editDataApppend() {
      this.orderdetail = JSON.parse(localStorage.getItem("orderdetail"));
      if (this.orderdetail.payment_status == 0) {
        this.paymentStatus = "Pending";
      } else {
        this.paymentStatus = "Done";
      }
      this.id = this.orderdetail.id;
      this.meunItemsTempArray = this.orderdetail.customerOrdersDetail;
      this.selected = this.orderdetail.restaurant.name;
      this.customerId =
        this.orderdetail.customerDetail.username +
        " - " +
        this.orderdetail.customerDetail.email;
      this.street_address = this.orderdetail.street_address;
      this.meunItems = this.orderdetail.meunItem;
      this.discountList = this.orderdetail.discounteditems;

      this.totalPrice = this.orderdetail.totalprice;
      this.orderStatus = this.orderdetail.order_status;
      this.deliveryTime = this.orderdetail.delivery_time;
      if (this.orderStatus == "accepted") {
        this.showDeliveryTimeInput = true;
      } else {
        this.showDeliveryTimeInput = false;
      }

      if (this.orderdetail.ordertype == "Delivery") {
        this.showInputForDelivery = true;
        this.deliveryAddress =
          this.orderdetail.orderDeliveryAddress.street +
          " " +
          this.orderdetail.orderDeliveryAddress.city +
          " " +
          this.orderdetail.orderDeliveryAddress.state;
        this.type = "1";
      } else {
        this.showInputForDelivery = false;
        this.type = "0";
      }

      this.id = this.orderdetail.id;
    },

    deleteData(object, actioType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actioType;
    },

    appendRow() {
      this.deliveryAddressArray.push(this.deliveryAddresstempArray[0]);
      this.$refs.autocomplete.clear();
      this.toasterMessage();
      if (this.deliveryAddressArray.length > 0) {
        this.deliveryAddressView = true;
      }
    },

    toasterMessage() {
      Vue.$toast.success("Delivery Address Save Please Check Blow", {
        position: "top-right",
        duration: 5000,
      });
    },

    closeModal(value) {
      this.isShowDiscountMenuList = value;
    },

    deleteImageRecord(object) {
      let index = this.loans.findIndex((x) => x.id === object.item.id);
      if (index !== -1) {
        this.editRestaurant.images.splice(index, 1);
        this.loans = this.editRestaurant.images;
        localStorage.removeItem("editRestaurant");
        localStorage.setItem(
          "editRestaurant",
          JSON.stringify(this.editRestaurant)
        );
      }

      this.alert = true;
      this.alertText = "Record Deleted Successfully";
      this.hideAlert();
    },

    showBulkPopupDeliveryAddress(object, actionType) {
      this.showModal = true;
      this.isShowModel = true;
      this.userObj = object;
      this.actionType = actionType;
    },

    deleteMeunItem(object) {
      let index = this.meunItemsTempArray.findIndex(
        (x) => x.menu_id === object.item.menu_id
      );
      this.meunItemsTempArray.splice(index, 1);
      let grandTotal =
        parseFloat(this.totalPrice) - parseFloat(object.item.total_price);
      this.totalPrice = parseFloat(grandTotal).toFixed(2);
      this.alert = true;
      this.alertText = "Record Deleted Successfully";
      this.hideAlert();
    },

    hideAlert() {
      setTimeout(() => (this.alert = false), 2000);
    },

    customLabel({ id, name }) {
      return `${id}—(${name})`;
    },

    totalPriceIncrement(price) {
      let grandTotal = parseFloat(this.totalPrice) + parseFloat(price);
      this.totalPrice = parseFloat(grandTotal).toFixed(2);
    },

    customLabelForCustomer({ id, email }) {
      return `${id}—(${email})`;
    },
    customLabelDeliveryAddress({ id, location }) {
      return `${id}—(${location})`;
    },
    onSubmit(evt) {
      try {
        // if (this.minimunOrderAmout > this.totalPrice) {
        //   evt.preventDefault();
        //   this.changeClasss = false;
        //   this.alertText =
        //     "Minimum Order Amount will be greater then " +
        //     this.minimunOrderAmout;
        //   this.alert = true;
        //   return false;
        // }
        const formData = new FormData();
        evt.preventDefault();
        formData.append("id", this.id);
        this.addLoaderClasses("save");
        formData.append("customer_id", this.customerId.id);
        formData.append("restaurant_id", this.selected.id);
        formData.append("totalprice", this.totalPrice);
        formData.append("order_type", this.type);
        formData.append("order_status", this.orderStatus);
        formData.append("street_address", this.street_address);
        formData.append("payment_status", 0);
        formData.append("menuitems", JSON.stringify(this.meunItemsTempArray));
        formData.append(
          "deliveryAddress",
          JSON.stringify(this.deliveryAddress)
        );

        const headers = {
          Authorization: "Bearer " + localStorage.getItem("id_token"),
        };
        axios
          .post(this.$path + this.apiUrl, formData, {
            headers,
          })
          .then((response) => {
            if (response.data.code == 200) {
              this.$router.push({ name: "orders" });
            }
            if (response.data.code == 422) {
              this.alert = true;
              this.alertText = response.data.message;
              this.removeLoaderClasses("save");
            }

            if (response.data.code == 501) {
              this.$router.push({ name: "login" });
            }
          })
          .catch(({ response }) => {
            if (response) {
              alert("Technical Issue");
            }
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    addLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },

    removeLoaderClasses(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },

    getLogo(logo) {
      return this.$IMAGE_BASE_URL + logo;
    },

    profileImageShow(e) {
      const file = e.target.files[0];
      this.profileImage = file;
      this.profileimageUrl = URL.createObjectURL(file);
    },

    getAllRestaurant() {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/user/restaurant/list", "", {
            headers,
          })
          .then((response) => {
            this.users = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    getAllCustomers() {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("id_token"),
      };
      try {
        axios
          .post(this.$path + "/user/user-dropdown", "", {
            headers,
          })
          .then((response) => {
            this.customers = response.data.data;
          });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.email = "";
      this.form.name = "";
      this.form.food = null;
      this.form.checked = [];
      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
  },
};
</script>
<style scoped>
#add-loan-btn {
  padding: 8px 25px 8px 25px;
}
.multiselect__single {
  font-size: 12px;
}
.multiselect * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 13px;
}

.multiselect__option--highlight:after {
  background-color: #428bca !important;
}

.alert {
  padding: 0.3rem 0.5rem;
}
.btn-info {
  background-color: #5cb700;
  border-color: #5cb700;
}
.btn-info:hover {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:active {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}

.btn-info:focus {
  border-color: #5cb700 !important;
  background-color: #5cb700 !important;
}
</style>
